import moment from 'moment'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { ROUTES } from 'static/menusConstants'
import { H4, P2 } from '@moneymade/moneymade-ui'
import Link from 'next/link'

import ImgLazy from 'components/ui/ImgLazy/ImgLazy'
import { getCdnImg } from 'utils/getters'
import styles from './CardArticle.module.scss'

const CardArticle = ({ slug, industries, logo, title, cardColor, published }) => {
  const date = useMemo(() => {
    const publishDate = `${new Date(published).getFullYear()}-${new Date(published).getMonth() + 1}-${new Date(
      published
    ).getDate()}`

    const today = moment().subtract(0, 'days').format('YYYY-M-D')
    const yesterday = moment().subtract(1, 'days').format('YYYY-M-D')
    const publishTime = moment(published).format('hh:mm a')

    return publishDate === today
      ? `Today at ${publishTime}`
      : publishDate === yesterday
      ? `Yesterday at ${publishTime}`
      : moment(published).format('MMM DD')
  }, [published])

  return (
    <Link href={`/${ROUTES.learnArticle}/${slug}`}>
      <a rel="noreferrer" className={styles.CardArticle}>
        <div className={styles.ImageContainer} style={{ backgroundColor: cardColor || '#35a577' }}>
          {logo ? <ImgLazy src={getCdnImg(logo, 280)} alt={title} weight={280} heigth={160} /> : null}
        </div>

        <div className={styles.Date}>
          {industries.length ? (
            <P2 weight="normal" className={styles.Industry}>
              {industries[0]?.name}
            </P2>
          ) : null}
          {industries.length ? <P2 className={styles.Name}> •</P2> : null}
          {published && <P2 className={styles.Name}>{date}</P2>}
        </div>

        <H4 weight="bold" type="heading">
          {title}
        </H4>
      </a>
    </Link>
  )
}

CardArticle.propTypes = {
  slug: PropTypes.string,
  industries: PropTypes.array,
  logo: PropTypes.string,
  title: PropTypes.string,
  cardColor: PropTypes.string,
  published: PropTypes.string
}

CardArticle.defaultProps = {
  slug: '',
  industries: [],
  logo: '',
  title: '',
  cardColor: '',
  published: ''
}

export default CardArticle
