import { Container, Icon, P2 } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'

import useTracker from 'hooks/useTracker'
import { getCdnImg } from 'utils/getters'
import ImgLazy from 'components/ui/ImgLazy/ImgLazy'
import styles from './HeaderBanner.module.scss'

const HeaderBanner = ({
  logo,
  imageSmall,
  backgroundColor,
  buttonText,
  title,
  text,
  buttonColor,
  buttonIconColor,
  buttonLink
}) => {
  const { handleUseTracker } = useTracker()

  return (
    <Container className={styles.HeaderBannerContainer} spaceTop={24}>
      <div className={styles.HeaderBanner} style={{ backgroundColor: backgroundColor || '#DBFFEE' }}>
        <div className={styles.Left}>
          {logo ? (
            <ImgLazy src={getCdnImg(logo, 56)} alt="logo" width={56} height={56} srcSet={getCdnImg(logo, 56, true)} />
          ) : // <Avatar src={getCdnImg(logo, 56)} alt="logo" width={56} height={56} srcSet={getCdnImg(logo, 56, true)} />
          null}
          <div>
            <p
              nestedStyles
              dangerouslySetInnerHTML={{
                __html: title
              }}
              className={styles.Title}
            />
            <p
              nestedStyles
              dangerouslySetInnerHTML={{
                __html: text
              }}
              className={styles.Text}
            />
          </div>
        </div>
        <div className={styles.Right}>
          {imageSmall ? <img src={imageSmall} alt="imageSmall" className={styles.ImageSmall} /> : null}
          <a
            href={buttonLink}
            onClick={() => handleUseTracker('BannerBtnClick', 'HeaderBannerClick', 'HeaderBannerClick')}
            target="_blank"
            rel="noreferrer noopener"
            className={styles.Button}
            style={{ backgroundColor: buttonColor || '#00B45E', color: buttonIconColor || '#FFFFFF' }}
          >
            <P2 nestedStyles dangerouslySetInnerHTML={{ __html: buttonText }} />
            <Icon icon="icon-arrow-right" size={14} />
          </a>
        </div>
      </div>
    </Container>
  )
}

HeaderBanner.propTypes = {
  logo: PropTypes.string,
  imageSmall: PropTypes.string,
  backgroundColor: PropTypes.string,
  buttonText: PropTypes.string,
  text: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonIconColor: PropTypes.string,
  buttonLink: PropTypes.string,
  title: PropTypes.string
}

HeaderBanner.defaultProps = {
  logo: '',
  imageSmall: '',
  backgroundColor: '',
  buttonText: '',
  text: '',
  buttonColor: '',
  buttonIconColor: '',
  buttonLink: '',
  title: ''
}

export default HeaderBanner
