import PropTypes from 'prop-types'
import { P1, P2 } from '@moneymade/moneymade-ui'

import useTracker from 'hooks/useTracker'
import ImgLazy from 'components/ui/ImgLazy/ImgLazy'
import { getCdnImg } from 'utils/getters'
import styles from './PlatformBanner.module.scss'

const PlatformBanner = ({ logo, backgroundColor, buttonLink, industry, text }) => {
  const { handleUseTracker } = useTracker()

  return (
    <a
      href={buttonLink}
      onClick={() => handleUseTracker('BannerBtnClick', 'MobBannerClick', 'MobBannerClick')}
      target="_blank"
      rel="noreferrer noopener"
      style={{ backgroundColor: backgroundColor || '#DBFFEE' }}
      className={styles.PlatformBanner}
    >
      <div className={styles.Left}>
        <div className={styles.Date}>
          {industry ? (
            <P2 weight="normal" className={styles.Industry}>
              {industry?.name}
            </P2>
          ) : null}
          {industry ? <P2 className={styles.Name}> •</P2> : null}
          {industry ? <P2 className={styles.Name}>Sponsored</P2> : null}
        </div>

        <P1
          weight="normal"
          nestedStyles
          dangerouslySetInnerHTML={{
            __html: text
          }}
          className={styles.Text}
        />
      </div>

      {logo ? (
        <ImgLazy src={getCdnImg(logo, 64)} alt={buttonLink} width={64} height={64} srcSet={getCdnImg(logo, 64, true)} />
      ) : // <Avatar width={64} height={64} src={logo} alt={buttonLink} />
      null}
    </a>
  )
}

PlatformBanner.propTypes = {
  logo: PropTypes.string,
  backgroundColor: PropTypes.string,
  industry: PropTypes.shape({ name: PropTypes.string, slug: PropTypes.string }),
  text: PropTypes.string,
  buttonLink: PropTypes.string
}

PlatformBanner.defaultProps = {
  logo: '',
  backgroundColor: '',
  industry: { name: '', slug: '' },
  text: '',
  buttonLink: ''
}

export default PlatformBanner
