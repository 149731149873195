import PropTypes from 'prop-types'

import { Card } from '@moneymade/moneymade-ui'
import styles from './LockComponent.module.scss'

const LockComponent = ({ lock, children }) => (
  <section className={styles.LockComponent}>
    {lock ? (
      <Card>
        <div className={`${lock ? styles.Lock : ''}`}>{children}</div>
      </Card>
    ) : (
      children
    )}
  </section>
)

LockComponent.propTypes = {
  children: PropTypes.node,
  lock: PropTypes.bool
}

LockComponent.defaultProps = {
  children: null,
  lock: false
}

export default LockComponent
