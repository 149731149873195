import moment from 'moment'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { ROUTES } from 'static/menusConstants'
import { P1, P2 } from '@moneymade/moneymade-ui'
import Link from 'next/link'

import ImgLazy from 'components/ui/ImgLazy/ImgLazy'
import { getCdnImg } from 'utils/getters'
import styles from './CardSmallArticle.module.scss'

const CardSmallArticle = ({ slug, industries, logo, logoThumbnail, title, cardColor, published }) => {
  const date = useMemo(() => {
    const publishDate = `${new Date(published).getFullYear()}-${new Date(published).getMonth() + 1}-${new Date(
      published
    ).getDate()}`

    const today = moment().subtract(0, 'days').format('YYYY-M-D')
    const yesterday = moment().subtract(1, 'days').format('YYYY-M-D')
    const publishTime = moment(published).format('hh:mm a')

    return publishDate === today
      ? `Today at ${publishTime}`
      : publishDate === yesterday
      ? `Yesterday at ${publishTime}`
      : moment(published).format('MMM DD')
  }, [published])

  return (
    <Link href={`/${ROUTES.learnArticle}/${slug}`}>
      <a rel="noreferrer" className={styles.CardSmallArticle}>
        <div className={styles.Left}>
          <div className={styles.Date}>
            {industries.length ? (
              <P2 weight="normal" className={styles.Industry}>
                {industries[0]?.name}
              </P2>
            ) : null}
            {industries.length ? <P2 className={styles.Name}> •</P2> : null}
            {published && <P2 className={styles.Name}>{date}</P2>}
          </div>

          <P1 weight="normal">{title}</P1>
        </div>

        <div className={styles.ImageContainer} style={{ backgroundColor: cardColor || '#35a577' }}>
          <ImgLazy src={getCdnImg(logoThumbnail || logo, 64)} alt={title} weight={64} heigth={43} />
        </div>
      </a>
    </Link>
  )
}

CardSmallArticle.propTypes = {
  slug: PropTypes.string,
  industries: PropTypes.array,
  logo: PropTypes.string,
  logoThumbnail: PropTypes.string,
  title: PropTypes.string,
  cardColor: PropTypes.string,
  published: PropTypes.string
}

CardSmallArticle.defaultProps = {
  slug: '',
  industries: [],
  logo: '',
  logoThumbnail: '',
  title: '',
  cardColor: '',
  published: ''
}

export default CardSmallArticle
