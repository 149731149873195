import { useSelector } from 'react-redux'

import QuizYesNo from './QuizYesNo'

const QuizYesNoContainer = props => {
  const { userData } = useSelector(({ auth }) => auth)

  const updatedProps = {
    ...props,
    userId: userData?.userId
  }

  return <QuizYesNo {...updatedProps} />
}

export default QuizYesNoContainer
