import { Container, H4, P1, Icon, useWindowSize } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { useMemo } from 'react'

import { ROUTES } from 'static/menusConstants'
import PlatformBanner from 'components/banners/PlatformBanner/PlatformBanner'
import CardArticle from './CardArticle/CardArticle'
import CardBigArticle from './CardBigArticle/CardBigArticle'
import CardSmallArticle from './CardSmallArticle/CardSmallArticle'

import styles from './Latest.module.scss'

const Latest = ({ articles, sponsor, banner }) => {
  const { screenWidth } = useWindowSize()

  const sortedArticles = useMemo(() => articles.filter(({ sponsored }) => !sponsored), [articles])

  const checkSponsored = useMemo(
    () => (sponsor.length ? sponsor[0] : sortedArticles.length ? sortedArticles[3] : {}),
    [sortedArticles, sponsor]
  )

  return (
    <Container className={styles.Latest}>
      <div className={styles.Header}>
        <H4 weight="semibold">Latest</H4>
        <Link href={`/${ROUTES.learnArticle}`}>
          <a rel="noreferrer" className={styles.Link}>
            <P1 weight="normal" className={styles.Title}>
              Show More
            </P1>
            <Icon icon="icon-arrow-right" size={15} />
          </a>
        </Link>
      </div>
      <div className={styles.Articles}>
        {screenWidth < 961 && screenWidth > 601 ? <CardBigArticle {...sortedArticles[1]} /> : null}

        <div className={styles.Left}>
          {screenWidth < 601 ? <CardArticle {...sortedArticles[1]} /> : null}
          <CardArticle {...sortedArticles[2]} />
          <CardArticle {...checkSponsored} />
        </div>

        {screenWidth > 961 ? <CardBigArticle {...sortedArticles[1]} /> : null}

        {screenWidth > 961 ? (
          <div className={styles.SmallArticles}>
            {screenWidth > 961
              ? sortedArticles
                  .slice(sponsor.length ? 3 : 4, sponsor.length ? 7 : 8)
                  .map((data, index) => <CardSmallArticle key={index} {...data} />)
              : null}

            {banner?.buttonLink ? <PlatformBanner {...banner} /> : null}
          </div>
        ) : null}
      </div>

      {screenWidth < 961 ? (
        <div className={styles.SmallArticles}>
          {screenWidth < 961 && screenWidth > 601
            ? sortedArticles
                .slice(sponsor.length ? 3 : 4, sponsor.length ? 4 : 5)
                .map((data, index) => <CardSmallArticle key={index} {...data} />)
            : screenWidth < 601
            ? sortedArticles
                .slice(sponsor.length ? 3 : 4, sponsor.length ? 5 : 6)
                .map((data, index) => <CardSmallArticle key={index} {...data} />)
            : null}

          {banner?.buttonLink ? <PlatformBanner {...banner} /> : null}
        </div>
      ) : null}
    </Container>
  )
}

Latest.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
  banner: PropTypes.shape({
    logo: PropTypes.string,
    backgroundColor: PropTypes.string,
    forPage: PropTypes.string,
    industry: PropTypes.shape({ name: PropTypes.string, slug: PropTypes.string }),
    text: PropTypes.string,
    buttonLink: PropTypes.string
  }),
  sponsor: PropTypes.arrayOf(PropTypes.object)
}

Latest.defaultProps = {
  articles: [],
  banner: {
    logo: '',
    backgroundColor: '',
    forPage: '',
    industry: { name: '', slug: '' },
    text: '',
    buttonLink: ''
  },
  sponsor: []
}

export default Latest
