import { useState } from 'react'
import { Container, P1, H3, MainButton, useLoader, H4, useWindowSize } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'

import useTracker from 'hooks/useTracker'
import { voteForCollectionCall } from 'api/calls'
import { getCdnImg } from 'utils/getters'
import ImgLazy from 'components/ui/ImgLazy/ImgLazy'
import styles from './QuizYesNo.module.scss'

const QuizYesNo = ({ text, question, userId, answerYes, answerNo, nameQuiz, image }) => {
  const { screenWidth } = useWindowSize()

  const [checker, setChecker] = useState('')
  const [quiz, setQuiz] = useState({})
  const { loaderStatus, loaderElement, setLoader } = useLoader(false)
  const { handleUseTracker } = useTracker()

  const handleSubmitResult = async type => {
    handleUseTracker('QuizYesNo', 'home', type)
    setChecker('submit')
    setLoader(true)
    const payload = {
      collectionSlug: 'homePage',
      quizName: nameQuiz,
      userId,
      buttons: [
        {
          name: type,
          value: true
        }
      ]
    }
    const { success, result } = await voteForCollectionCall(payload)()
    const staticType = [answerYes, answerNo]
    if (success) {
      const filteredData = result
        .find(({ quizName }) => quizName === nameQuiz)
        ?.results.filter(({ name }) => staticType.includes(name))

      setLoader(false)

      setQuiz({ type, filteredData: filteredData.reverse() })
    }
  }

  return (
    <Container className={styles.QuizYesNoContainer}>
      <div className={styles.QuizYesNo}>
        {image ? (
          <ImgLazy
            src={getCdnImg(image, screenWidth < 601 ? 180 : 240)}
            alt={text}
            width={screenWidth < 601 ? 180 : 240}
            height={screenWidth < 601 ? 120 : 160}
          />
        ) : null}

        {loaderStatus ? (
          <div className={styles.Loading}>{loaderElement}</div>
        ) : Object.keys(quiz).length ? (
          <div className={styles.ResultQuiz}>
            <P1 weight="semibold" className={styles.Title}>
              Results
            </P1>
            <div className={styles.Result}>
              {quiz?.filteredData?.map(({ name, result }, index) => (
                <div key={index} className={styles.Content}>
                  <H3 weight="bold">{name}</H3>
                  <div className={styles.ConatinerProcent}>
                    <div className={styles.Procent}>
                      <div className={styles.LoadingDefault}>
                        <div style={{ width: `${result}%` }} className={styles.ProgressDefault} />
                      </div>
                    </div>
                    <H4 weight="semibold" type="heading">
                      {result}%
                    </H4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {checker !== 'submit' ? (
          <div className={styles.ModContent}>
            <div className={styles.TextContent}>
              <P1 weight="semibold">{text}</P1>
              <H3 weight="bold" type="heading">
                {question}
              </H3>
            </div>
            <div className={styles.Buttons}>
              <MainButton fontType="main" weight="normal" size="md" onClick={() => handleSubmitResult(answerYes)}>
                {answerYes}
              </MainButton>
              <MainButton fontType="main" weight="normal" size="md" onClick={() => handleSubmitResult(answerNo)}>
                {answerNo}
              </MainButton>
            </div>
          </div>
        ) : null}
      </div>
    </Container>
  )
}

QuizYesNo.propTypes = {
  text: PropTypes.string,
  question: PropTypes.string,
  answerYes: PropTypes.string,
  answerNo: PropTypes.string,
  nameQuiz: PropTypes.string,
  image: PropTypes.string,
  userId: PropTypes.string
}

QuizYesNo.defaultProps = {
  text: '',
  question: '',
  answerYes: '',
  answerNo: '',
  nameQuiz: '',
  image: '',
  userId: ''
}

export default QuizYesNo
