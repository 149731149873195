/**
 * This function help find Banner from CMS for different pages. its can be /trending /discover..
 * @param {[]} items
 * @param {string} [key]
 * @returns {}
 */
export const findBanner = (banners, queryPage) => {
  const bannerForPage = banners.filter(({ forPage }) => forPage.includes(queryPage))

  return {
    header: bannerForPage.find(({ type }) => type.includes('header')) || {},
    inText: bannerForPage.find(({ type }) => type.includes('inText')) || {},
    rightRail: bannerForPage.find(({ type }) => type.includes('rightRail')) || {},
    platformBanner: bannerForPage.find(({ type }) => type.includes('platformBanner')) || {}
  }
}
export const rankInIndustriesNew = (investments, currentInvestment) => {
  const result = {}
  const filteredPlatform = investments.filter(({ industry }) => industry === currentInvestment?.industry) || []
  const key = currentInvestment?.industry
  result[key] = filteredPlatform.length

  return result
}
