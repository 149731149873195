import { Icon, Avatar, H5, P3 } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'

import useTracker from 'hooks/useTracker'
import { getCdnImg } from 'utils/getters'
import styles from './MobBanner.module.scss'

const MobBanner = ({
  logo,
  width,
  whiteSpace,
  imageSmall,
  title,
  backgroundColor,
  text,
  buttonIconColor,
  buttonLink,
  header
}) => {
  const { handleUseTracker } = useTracker()

  return (
    <a
      href={buttonLink}
      onClick={() => handleUseTracker('BannerBtnClick', 'MobBannerClick', 'MobBannerClick')}
      target="_blank"
      rel="noreferrer noopener"
      className={`${styles.MobBanner} ${header ? styles.Header : ''}`}
      style={{
        backgroundColor: backgroundColor || '#DBFFEE',
        width: width || '100%',
        whiteSpace: whiteSpace || 'nowrap'
      }}
    >
      <div className={styles.Left}>
        {imageSmall ? (
          <img src={imageSmall} alt="imageSmall" className={styles.ImageSmall} />
        ) : logo ? (
          <Avatar src={getCdnImg(logo, 40)} alt="logo" width={40} height={40} />
        ) : null}
        <div className={styles.Contant}>
          {imageSmall && logo ? <Avatar src={getCdnImg(logo, 40)} alt="logo" width={40} height={40} /> : null}
          <H5
            weight="semibold"
            nestedStyles
            dangerouslySetInnerHTML={{
              __html: title
            }}
            className={styles.Title}
          />
          <P3
            weight="light"
            nestedStyles
            dangerouslySetInnerHTML={{
              __html: text
            }}
            className={styles.Text}
          />
        </div>
      </div>
      <div className={styles.Right}>
        <a
          href={buttonLink}
          onClick={() => handleUseTracker('BannerBtnClick', 'MobBannerClick', 'MobBannerClick')}
          target="_blank"
          rel="noreferrer noopener"
          className={styles.Button}
          style={{ color: buttonIconColor || '#FFFFFF' }}
        >
          <Icon icon="icon-point-right" size={20} />
        </a>
      </div>
    </a>
  )
}

MobBanner.propTypes = {
  logo: PropTypes.string,
  imageSmall: PropTypes.string,
  whiteSpace: PropTypes.string,
  backgroundColor: PropTypes.string,
  text: PropTypes.string,
  buttonIconColor: PropTypes.string,
  buttonLink: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  header: PropTypes.bool
}

MobBanner.defaultProps = {
  logo: '',
  imageSmall: '',
  backgroundColor: '',
  whiteSpace: '',
  text: '',
  buttonIconColor: '',
  buttonLink: '',
  title: '',
  width: '',
  header: false
}

export default MobBanner
